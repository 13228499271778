import { Modal } from "antd";
import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import "./chat-bot-mobile.scss";
import { createFeedback } from "@/src/app/service";
const { TextArea } = Input;
const FormItem = Form.Item;

const Feedback = ({ openModalFeedback, setOpenModalFeedback }) => {
  const [form] = Form.useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async () => {
    try {
      setIsLoading(true);
      const fieldsValue = await form.validateFields();
      await createFeedback(fieldsValue);
      setIsSuccess(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to validate form:", error);
      setIsLoading(false);
    }
  };
  return (
    <Modal
      className="modal-chatbot-mobile"
      open={openModalFeedback}
      onCancel={() => {
        setOpenModalFeedback(false);
        form.resetFields();
      }}
      footer={null}
      closable={true}
      width={380}
      destroyOnClose={true}
      centered
    >
      <div className="wpFormChatboxMobile">
        <div className="chatHeader">
          <span>Hòm thư góp ý</span>
        </div>
        <div className="chatBody">
          <div className={"chatContainer"}>
            <div className={"rowChat"}>
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                className={"chatContent"}
              >
                <FormItem
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên",
                    },
                  ]}
                  label="Họ và tên"
                >
                  <Input
                    className="chatBoxInput"
                    placeholder="Họ và tên"
                    style={{ width: "100%" }}
                  />
                </FormItem>
                <FormItem
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập số điện thoại",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const phoneNumberRegex =
                          /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
                        if (value && !phoneNumberRegex.test(value)) {
                          return Promise.reject("Số điện thoại không hợp lệ!");
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                  label="Số điện thoại"
                >
                  <Input
                    placeholder="Số điện thoại"
                    className="chatBoxInput"
                    style={{ width: "100%" }}
                    inputMode="numeric"
                  />
                </FormItem>
                <FormItem
                  name="content"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập nội dung",
                    },
                  ]}
                  label="Nội dung"
                >
                  <TextArea
                    placeholder="Nội dung"
                    style={{ width: "100%" }}
                    rows={6}
                    autoSize={{ minRows: 5 }}
                  />
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
        <div className="chatFooter">
          <Button
            className={`btnChatBox ${isSuccess ? "disabledBtn" : ""}`}
            onClick={onFinish}
            disabled={isSuccess}
            loading={isLoading}
          >
            Gửi yêu cầu
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Feedback;
