import { useCallback, useMemo, useState } from "react";
import { Avatar, Button, Form, Input, Modal } from "antd";
import { createContactAdvice } from "@/src/app/service";
import { usePathname } from "next/navigation";
import { UserOutlined } from "@ant-design/icons";
import Image from "next/image";
import iconUpdown from "../../../public/icon/icon-up-down.svg";
import iconArrow from "@/public/icon/arrow-float-button.svg";
import chatAlert from "@/public/icon/chat-alert.svg";
import ImageCommon from "../image/image";
import logoNhakhoaHub from "../../../public/image/logo-nhakhoahub-chat.png";
import "./chatbot.scss";
import moment from "moment";
import FormChatbot from "./form-chat-bot";
import FormChatbotMobile from "./form-chat-bot-mobile";

const { TextArea } = Input;
const FormItem = Form.Item;

const Chatbot = () => {
  const path = usePathname();
  if (path == "/embed-get-coupon") return;
  const [openFormChat, setOpenFormChat] = useState(false);
  const [classFormChat, setClassFormChat] = useState("");

  const isPathValid = useMemo(() => {
    return path.startsWith("/phong-kham/") || path.startsWith("/bac-si/");
  }, [path]);

  const [openModalChatbotMobile, setOpenModalChatbotMobile] = useState(false);

  const showFormChat = () => {
    // setClassFormChat("up");
    // setOpenFormChat(true);
    setOpenModalChatbotMobile(true);
  };
  const closeFormChat = () => {
    setClassFormChat("down");
    setTimeout(() => setOpenFormChat(false), 200);
  };

  return (
    <div className="wpChatbox">
      {!openFormChat && (
        <>
          <div className="showChatbot" onClick={showFormChat}>
            <div>Liên hệ tư vấn</div>
            <Image
              src={iconUpdown}
              alt="iconUpdown"
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
          {!isPathValid && (
            <div className="showChatbotMobile" onClick={showFormChat}>
              <div className="floatButton">
                <Image
                  unoptimized
                  src={logoNhakhoaHub}
                  width={52}
                  height={52}
                  style={{ objectFit: "none" }}
                  alt="logo NhakhoaHub"
                />
                <Image
                  src={iconArrow}
                  className="arrowFloatButton"
                  width={14}
                  height={14}
                  alt="lượng tin nhắn"
                />
                {/* {!isSuccess && (
                  <Image
                    src={chatAlert}
                    className="chatAlert"
                    width={18}
                    height={18}
                    alt="alert"
                  />
                )} */}
              </div>
            </div>
          )}
        </>
      )}
      {openFormChat && (
        <FormChatbot closeFormChat={closeFormChat} classFormChat={"up"} />
      )}
      <Modal
        className="modal-chatbot-mobile"
        open={openModalChatbotMobile}
        onCancel={() => {
          setOpenModalChatbotMobile(false);
        }}
        footer={null}
        closable={true}
        width={380}
        destroyOnClose={true}
        centered
      >
        <FormChatbotMobile clinic={null} />
      </Modal>
    </div>
  );
};

export default Chatbot;
